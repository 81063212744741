import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`RPLT Lab`}</h1>
    <p><a parentName="p" {...{
        "href": "https://rongyi.io"
      }}>{`荣懿`}</a>{`旗下`}<strong parentName="p">{`编程语言&工具`}</strong>{`实验室`}</p>
    <p>{`Rong's `}<strong parentName="p">{`Programming Language & Tools`}</strong>{` Lab`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      