import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`关于`}</h2>
    <p>{`我们的主要探索方向: 编程语言设计、编译原理、新计算硬件支持、可微分编程等，重点方向是软件开发的可维护性、运行性能和工具易用性。`}</p>
    <p>{`RPLT实验室同时也为`}<a parentName="p" {...{
        "href": "https://lumos.dev"
      }}>{`Lumos编程语言`}</a>{`提供技术指导和预研。`}</p>
    <blockquote>
      <p parentName="blockquote">{`计算基建，驱动下个十年的软件开发`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      